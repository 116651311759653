export default {
    en: {
        bottomBar: {
            home: 'Home',
            browse: 'Browse',
            messages: 'Messages',
            bookings: 'Bookings',
            more: 'More',
            services: 'Services',
        },
        yourTokens: 'Your days: ',
        installModal: {
            heading:
                '👋 We recommend installing the app for the best experience',
            installButton: 'Install app',
            dismissButton: 'No thanks',
            dismissPassive: 'Dismiss',
            tap: 'Tap',
            thenAdd: 'then "Add to Home Screen" to install the app.',
        },
        yachtItem: {
            cabins: 'CABINS',
            passengers: 'PASSENGERS',
        },
        updateAppModal: {
            title: 'A new update is available',
            current: '(current {version})',
            updateNow: 'Update now',
        },
        appInvitation: {
            sureCancel: 'Are you sure you want to cancel?',
            once: "Once you leave, you'll have to follow the link in your email to re-activate your account",
            leave: 'Leave',
            stay: 'Stay',
            welcome: 'Welcome to NEOYOT',
            sent: 'Sent',
            sentExplanation:
                'A new account activation link has been sent to your email. If the message is not in your inbox, pleasecheck your Spam folder.',
            sendNew: 'Send a new invitation link',
            enterEmail:
                'Please enter your email for the account you want to activate.',
            notFound:
                " We're sorry, but we couldn't find any accounts registered to this email.",
            sendNewLink: 'Send new link',
            cancel: 'Cancel',
            hi: 'Hi {name}, set a new password to activate your account',
            email: 'E-mail',
            newPassword: 'New password',
            eightChars: 'New password needs to be 8 characters or longer.',
            setNew: 'Set new password & activate',
            expiredHeading: 'Invitation expired',
            sevenDays:
                'The invitation link is only valid for 7 days. Please send a new invitation link to your email.',
            sendToMyEmail: 'Send a new link to my email',
            goBack: 'Go back',
        },
        pages: {
            services: {
                heading: 'Services',
            },
            login: {
                heading: 'Welcome aboard the yacht subscription',
                login: 'Login',
                email: 'Email',
                password: 'Password',
                forgotPassword: 'Forgot password?',
            },
            resetPassword: {
                resetPassword: 'Reset password',
                desc: 'Enter the email associated with your account and we will send you instructions to reset your password.',
                emailEmpty: 'E-mail is empty',
                couldntFind:
                    "We couldn't find an account associated with this email. Please double check that you have entered it correctly. If you are still having trouble, please contact NEOYOT",
                resetLinkSent: 'Reset link sent',
                instructionsSent:
                    'Instructions on how to reset your password has been sent to your email. If the messsage is not in your inbox, please check your Spam folder.',
                newPassword: 'New password',
                eightChars: 'Your password needs to be 8 characters or longer.',
                expiredHeading: 'Request expired',
                expiredExplanation:
                    " We're sorry, but this request has now expired as it had been made more than 24 hours ago, or you have made another new request. Please make a new request.",
                newRequest: 'Make a new request',
                cancel: 'Cancel',
                success: 'Your password has been reset',
                loggedIn: 'You are now logged in',
            },
            home: {
                yourNextBooking: 'Your next booking',
                notSubscribedToAny: 'You are not subscribed to any yachts',
                noYachtsInCollection: 'No yachts in collection.',
                accountActivated: 'Account activated',
                loggedIn: 'You are now logged in',
                goodMorning: 'Good morning, {first_name}',
                goodAfternoon: 'Good afternoon, {first_name}',
                goodEvening: 'Good evening, {first_name}',
                findAvailable: 'Find available',
                today: 'Today',
                tomorrow: 'Tomorrow',
                search: 'Search',
                featured: 'NEOYOT Fleet',
                allTimes: 'All times',
                subscriptionExpiredMessage:
                    'One of your subscriptions has expired',
                yourYachts: 'Your yachts',
                other: 'Other services',
                news: 'News',
                noYachts: 'No active yachts in collection',
            },
            browse: {},
            messages: {
                viewConversation: {
                    button: 'Send',
                    typeSomething: 'Type something...',
                    startOfConvo: 'Start of conversation',
                    startWith: 'Start a new conversation with Captain {name}',
                },
                explanation: "Message captains that you've made bookings with.",
                captain: 'Captain',
                sendNew: 'Send a new message',
                you: 'You: ',
                new: 'New',
                empty: "You can message captains that you've made bookings with here. You have not made any bookings",
                notFound: 'Not found',
                cantFindConversation: "Can't find conversation",
                notFoundDesc:
                    "Sorry, we couldn't find the conversation you were looking for. It may have been deleted, moved, or modified. If you believe this to be an error, please contact NEOYOT.",
            },
            viewYacht: {
                chooseBooking: 'Choose booking',
                specifications: 'Specifications',
                documents: 'Documents',
                engines: 'Engines',
                flag: 'Flag',
                notSpecified: 'Not specified',
                readMore: 'Read more',
                readLess: 'Read less',
                mapLocation: 'Map location',
                location: 'Location',
                length: 'Length',
                yearOfManufacture: 'Year of manufacture',
                beam: 'Beam',
                weight: 'Weight',
                hullLength: 'Hull length',
                hullMaterial: 'Hull material',
                width: 'Width',
                navigationCategory: 'Navigation category',
                fuelCapacity: 'Fuel capacity (litres)',
                notSubscribed: 'You are not subscribed to this yacht',
                notFound: 'Not found',
                yachtNotFound: 'Yacht not found',
                notFoundDesc:
                    "Sorry, we couldn't find the yacht you were looking for. It may have been deleted, moved, or modified. If you believe this to be an error, please contact NEOYOT",
            },
            bookings: {
                tapToViewMore: 'Tap to view more',
                activeBookings: 'Active',
                previousBookings: 'Previous',
                noActive: 'No active bookings found',
                seePrevious: 'See previous expired bookings',
                noPrevious: 'No previous expired bookings found',
                seeActive: 'See active bookings',
                viewAllBookings: 'View all bookings',
                cannotCancelTitle: 'Cannot cancel booking',
                afterDeadline:
                    'It is after the cancellation deadline of {hours} hours',
                areYouSureCancel:
                    'Are you sure you want to cancel this booking?',
                willNotGetDaysBack:
                    'You will not get your days back, unless cancelling due to bad weather.',
                iAmCancellingDueToBadWeather:
                    'I am cancelling due to bad weather',
                cannotReverse: 'This action cannot be reversed.',
                singularToken: '{tokens} day will be refunded',
                multipleTokens: '{tokens} days will be refunded',
                reasonPlaceholder: 'Reason for cancellation (optional)...',
                yes: 'Yes',
                no: 'No',
                booking: 'Booking',
                bookingCreated: 'Booking created',
                bookingInOut: 'Booking check-in & check-out',
                captain: 'Captain',
                tapToSendMessage: 'Tap to send message',
                captainNotFound: 'Captain not found',
                status: 'Status',
                tokensUsed: 'Days used',
                cancelDeadline: 'Cancel deadline',
                notesAndSpecialRequests: 'Notes & special requests',
                didNotProvide:
                    'You did not provide any notes or special requests',
                cancelBooking: 'Cancel booking',
                cancellationInProgress: 'Cancelling your booking...',
                bookingType: 'Booking type',
                cancelError:
                    'We could not cancel your booking due to an unexpected error. Please contact NEOYOT',
                ended: 'Ended',
                active: 'Active, ending',
                starts: 'Starts',
                cancelled: 'Cancelled',
                bookingID: 'Booking ID',
                cantFindBooking: "Can't find this booking",
                cantFindBookingDesc:
                    " We're unable to find this booking. It may have been deleted or modified, please return to the previous page and try to find your booking. If you believe this to be an error, please contact NEOYOT.",
                yourBookingHasBeenCancelled: 'Your booking has been cancelled',
                daysNotRefundedOneHourAfterDeparture:
                    'Unfortunately, your days will not be refunded as you are cancelling more than an hour after departure.',
            },
            bookNow: {
                slotSelector: {
                    title: 'Book {name}',
                    whatBookingType:
                        'What type of booking would you like to make?',
                    howLong: 'How long would you like to book for?',
                    when: 'When would you like to book?',
                    slotsAvailableInTheNext:
                        'Days available in the next {daysOrHours}',
                    available: 'Slots available in {date}',
                    explanation:
                        'Please select your check-in and check-out dates from the calendar below',
                    availableFrom: 'Available from',
                    button: 'Select check-in & out',
                    between: 'Between',
                    durationBetween: 'Duration must be between',
                    oneHour: '1 hour',
                    to: 'to',
                    moreThan: 'More than',
                    durationMoreThan: 'Duration must be more than',
                    month: 'Month',
                    year: 'Year',
                    noneFound:
                        'No available slots were found in {month} with the selected booking length.',
                    noSlotsFound: 'No available days found',
                    try: 'Try changing the booking duration or changing the month.',
                    day: 'day',
                    hour: 'hour',
                    availableToday: 'Days available',
                    displayAvailableSlotsErrorTitle:
                        'Unable to display available days',
                    displayAvailableSlotsError:
                        'An error has occured. Please let us know of this error.',
                    sorryForInconvenience: "We're sorry for any inconvenience",
                    expired: 'EXPIRED',
                    subscriptionExpired: 'Subscription expired',
                    subscriptionExpiredDesc:
                        "You can't make a booking for this type, as your subscription for this booking type has expired. Please contact NEOYOT to renew your subscription",
                    contactSupport: 'Contact support',
                    earliestSubscriptionHasntStarted:
                        "Your earliest subscription to this yacht hasn't started yet",
                    itIsScheduledToStartedOn:
                        'It is scheduled to start on {date}. Please check back later.',
                    ifYouHaveAnyQuestions:
                        'If you have any questions, please contact support.',
                    yourBooking: 'Your booking',
                    bookingCantBeMade:
                        "We're sorry, but this booking cannot be made. Please contact support if this issue still persists.",
                    book: 'Book',
                    availableOnly: 'Available',
                    booked: 'Booked',
                    accreditationHasntStartedButCanBook:
                        "Your earliest subscription hasn't started yet, but you are allowed to make a booking from the start of your subscription",
                    yourSubscriptionFrom:
                        'Your current subscription is from {start} to {end}',
                },
                bookingDetails: {
                    title: 'Booking details',
                    conditionsFor: 'Conditions',
                    maxBooking: 'Maximum booking duration {hours} hours',
                    cancelDeadline: 'Cancellation deadline {hours}',
                    checkIn: 'Check-in',
                    checkOut: 'Check-out',
                    date: 'Date',
                    hour: 'Hour',
                    duration: 'Duration',
                    tokensRequired: 'Days required',
                    yourTokens: 'Your days',
                    next: 'Next',
                    noTokensRequired: 'No days required',
                    noConditions: 'No conditions',
                    moreThan: 'More than',
                    invalid: 'Invalid',
                    notEnoughForMoreThan:
                        "You don't have enough days available for a booking more than",
                    errors: {
                        checkOutAfterMaxEnd:
                            'Check-out cannot be after the maximum end {time}',
                        durationMoreThanMaximum:
                            'Booking duration is more then allowed maximum of {time}. Please reduce booking duration',
                        durationMoreThan:
                            'Booking duration needs to be more than {time}. Please increase booking duration',
                        durationLessThan:
                            'Booking duration is less than allowed minimum of {time}. Please increase booking duration',
                        inOutSame: 'Check-in and check-out cannot be the same',
                        inAfterOut: 'Check-in cannot be after check-out',
                        inBeforeStart:
                            'Check-in cannot be before the minimum start of {time}',
                        atLeastOneHour: 'Duration must be at least 1 hour',
                        notEnoughTokens: "You don't have enough days available to make this booking",
                    },
                    daysAvailableAccordingToYourSubscription:
                        'Days available in each month according to your subscription and days required to make this booking',
                    month: 'Month',
                    available: 'Available',
                    required: 'Required',
                },
                bookingConfirmation: {
                    success: 'Success',
                    bookingMade: 'Your booking has been made',
                    confirmBooking: 'Confirm booking',
                    duration: 'Duration',
                    tokensRequired: 'Days required',
                    notes: 'Notes & special requests',
                    error: 'Error',
                    processing: 'Processing...',
                    anotherBookingMade:
                        "We're sorry, but another booking within this slot has been made. We couldn't complete your booking. Please try booking another slot<br>We apologise for any inconvenience",
                    notEnoughTokens:
                        "We're sorry, but you don't have enough days available to make this booking",
                    bookingError:
                        "We're sorry, but this booking couldn't be made. This is an unexpected error and we apologise for any inconvenience. Please notify NEOYOT of this issue. <br />In the meantime, try booking another date.<br />Thank you.",
                },
                tokens: 'days',
            },
            pageNotFound: {
                heading: 'Not found',
                explanation: 'The page you are looking for could not be found',
                location:
                    "Its location could have been changed or it doesn't exist. We apologise for any inconvenience.",
                back: 'Go back home',
            },
            more: {
                logout: 'Logout',
                contactSupport: 'Contact support',
                subscriptionEnds: 'Subscription ends',
                tapToEdit: 'Tap to edit',
                noSubscriptions: 'You have no subscriptions',
                expired: 'Expired',
                active: 'Active',
                notStarted: 'Not started',
                startsIn: 'Starts in',
                myAccount: {
                    myAccount: 'My account',
                    firstName: 'First name',
                    lastName: 'Last name',
                    email: 'E-mail',
                    phone: 'Phone',
                    secondaryPhone: 'Secondary phone',
                    address: 'Address',
                    additionalAddress: 'Additional address',
                    postcode: 'Postcode',
                    city: 'City',
                    country: 'Country',
                    tapToChangePhoto: 'Tap to change photo',
                    resetPasswordModal: {
                        currentPassword: 'Current password',
                        newPassword: 'New password',
                        passwordLengthError:
                            'New passwrod needs to be at least 8 or more characters',
                    },
                },
                contactSupportPage: {
                    phone: 'Phone',
                    website: 'Website',
                },
                mySubscription: {
                    mySubscription: 'My Subscriptions',
                    moreDetails: 'More details',
                    startDate: 'Start date',
                    expiryDate: 'Expiry date',
                    expires: 'Expires in',
                    expired: 'Expired',
                    currentSubscriptionLength: 'Current subscription length',
                    subscriptionAmount: 'Subscription amount',
                    day: '{day} day',
                    days: '{days} days',
                    hours: '{hours} hours',
                    hour: '{hour} hour',
                    minutes: 'minutes',
                    seconds: 'seconds',
                    length: 'Length',
                    amount: 'Amount',
                    yachtAccess: 'Yacht access',
                    tokensAvailable: 'Days available',
                    noSubscriptions: 'You have no subscriptions',
                    spansMultipleMonths:
                        'This subscription spans over multiple months. Days available for booking will be reset at the start of every month.',
                    month: 'Month',
                    available: 'Available',
                },
                other: 'Other',
                invoices: {
                    invoices: 'Invoices',
                    invoice: 'Invoice',
                    explanation:
                        "Once you've made a payment, invoices for your payments will show up here. It may take some time for the invoice to show.",
                    questions:
                        'If you have any questions, please do not hesitate to ',
                    contactUs: 'contact us',
                    noneFound: 'No invoices found.',
                },
                subscriptionContract: {
                    heading: 'Subscription contract',
                },
                feedback: {
                    feedback: 'Send feedback',
                    feedbackSent: 'Feedback sent',
                    thanks: 'Thank you for your suggestion.',
                    explanation:
                        'If you have any suggestions for improvements, or if you have encountered any problems, you can tell us about it here. However, if you need to get in touch with us right away, please',
                    contactUs: 'contact us',
                    instead: 'instead',
                    appreciate:
                        'We highly appreciate your input as we strive to create the best experience possible.',
                    message: 'Message',
                    invalid: 'Message cannot be blank',
                    rate: 'Rate your experience',
                    anonymous: 'Send this feedback anonymously',
                    send: 'Send',
                    type: 'Type your message here...',
                },
                aboutApp: {
                    aboutApp: 'About app',
                    tos: "NEOYOT's Terms of Service",
                    tosDescription: "Read NEOYOT's Terms of Serivce",
                    osl: 'Open-source licenses',
                    oslDescription: 'License details for open-source software',
                    version: 'Version',
                },
            },
            food: {
                errors: {
                    failedToGetItemsInThisCategory:
                        'ERROR: Failed to get items in this category',
                    selectedDeliverytimeBeforeNow:
                        'Your selected delivery time needs to be before the time now',
                    someItemsBecameUnavailable:
                        'Some items in your cart has become unavailable',
                    unavailableItemsRemoved:
                        'The items above has been removed from your cart because they are unavailable or out of stock. Please try placing your order again.',
                    orderCouldntBeCompleted:
                        "An unknown error has occured. Your order couldn't be completed",
                    notSubscribedToAnyYachts:
                        'A delivery cannot be made because you are not subscribed to any yachts',
                    failedToGetItemsInThisCuisine:
                        'ERROR: Failed to get items in this cuisine',
                    orderNotFound: 'Order not found',
                    cantFindOrder:
                        "Sorry, we couldn't find the order you were looking for. Please try again",
                },
                nav: {
                    featured: 'Featured',
                    cuisines: 'Cuisines',
                    categories: 'Categories',
                    orders: 'Orders',
                },
                itemNameOrDescription: 'Item name or description',
                boat: 'Boat',
                deliveryInstructionsPlaceholder:
                    'Put items in aft deck refrigerator',
                addYourRequest:
                    "Add your request (subject to restaurant's discretion)",
                basePrice: 'Base price',
                optionalMax: 'Optional, max {max}',
                optional: 'Optional',
                pickAtLeast: 'Pick at least {min}',
                pickMax: 'Pick {min}, max {max}',
                pickOne: 'Pick 1',
                pick: 'Pick',
                noteToRestaurant: 'Note to restaurant',
                itemAlreadyInBasket: 'This item is already in your basket',
                removeFromBasket: 'Remove from basket',
                updateItem: 'Update item',
                addToBasket: 'Add to basket',
                selectAtLeast: 'Select at least {items}',
                dontSelectMoreThan: "Don't select more than {items}",
                cancellationReasonOptional: 'Cancellation reason (optional)',
                theReasonForYourCancellation:
                    'The reason for your cancellation (optional)',
                cancelOrder: 'Cancel order',
                dontCancel: "Don't cancel",
                categories: 'Categories',
                mostPopular: 'Most popular',
                leastPopular: 'Least popular',
                highestPrice: 'Highest price',
                lowestPrice: 'Lowest price',
                aToZ: 'A to Z',
                zToA: 'Z to A',
                noItemsWereFound: 'No items were found',
                deliveryDetails: 'Delivery details',
                whenToDeliver: 'When to deliver',
                selectDeliveryTime: 'Select delivery time',
                deliverTo: 'Deliver to',
                deliveryInstructions: 'Delivery instructions',
                subtotal: 'Subtotal',
                sendingYourOrder: 'Sending your order...',
                confirm: 'Confirm',
                now: 'Now',
                specific: 'At a specific time',
                popular: 'Popular',
                viewAll: 'View all',
                recentlyAdded: 'Recently added',
                foodAndBeverages: 'Food and Beverages',
                search: 'Search',
                basket: 'Basket',
                item: 'item',
                items: 'items',
                myBasket: 'My Basket',
                edit: 'Edit',
                yourBasketIsEmpty: 'Your basket is empty',
                checkout: 'Checkout',
                done: 'Done',
                pleaseTryAgain: 'Please try again',
                yourOrderHasBeenPlaced: 'Your order has been placed',
                youWillReceiveAnEmail:
                    "You will receive an email when it's being delivered",
                myOrders: 'My Orders',
                order: 'Order',
                weveReceivedYourOrder: "We've received your order",
                preparingYourOrder: 'Preparing your order',
                delivering: 'Delivering',
                delivered: 'Delivered',
                cancelledBySubscriber: 'Cancelled by subscriber',
                weCouldntFulfillYourOrder: "We couldn't fulfill your order",
                unknownItem: 'Unknown item',
                moreItems: '+ {number} more items',
                noResultsFound: 'No results found',
                notSpecified: 'Not specified',
                cuisine: 'Cuisine',
                other: 'Other',
                updateItemInBasket: 'Update item in basket',
                unavailable: 'Unavailable',
                youHaveRequestedThisOrderToBeDeliveredAt:
                    'You have requested for this order to be delivered at ',
                youHaveRequestedThisOrderToBeDeliveredAsap:
                    'Your have requested for this order to be delivered as soon as possible',
                reason: 'Reason',
                noReasonGiven: 'No reason given',
                createdOn: 'Created on',
                orderDetails: 'Order details',
                unknownPreference: 'Unknown preference',
                contactNEOYOT: 'Contact NEOYOT',
                inBasket: 'In basket',
            },
        },
        misc: {
            later: 'Later',
            engine: 'Engine',
            horsepower: 'horsepower',
            transmission: 'transmission',
            propellers: 'propellers',
            uploading: 'Uploading',
            none: 'none',
            tokens: 'DAYS',
            refresh: 'Refresh',
            cannotBookNoCaptain: 'Cannot be booked—no captain',
            back: 'Back',
            goBack: 'Go back',
            modal: {
                done: 'Done',
                cancel: 'Cancel',
                save: 'Save',
                confirmLogout: 'Are you sure you want to log out?',
                logout: 'Log out',
            },
            day: 'day',
            days: 'days',
            hours: 'hours',
            minutes: 'minutes',
        },
    },
    fr: {
        bottomBar: {
            home: 'Accueil',
            browse: 'Parcourir',
            messages: 'Messages',
            bookings: 'Réservations',
            more: 'Plus',
            services: 'Services',
        },
        yourTokens: 'Vos jours : ',
        installModal: {
            heading:
                "👋 Nous recommandons d'installer l'application pour une meilleure expérience",
            installButton: "Installer l'application",
            dismissButton: 'Non merci',
            dismissPassive: 'Rejeter',
            tap: 'Tapez',
            thenAdd:
                "puis \"Sur l'écran d'accueil\" pour installer l'application.",
        },
        yachtItem: {
            cabins: 'CABINES',
            passengers: 'PASSAGERS',
        },
        updateAppModal: {
            title: 'Une nouvelle mise à jour est disponible',
            current: '(actuelle {version})',
            updateNow: 'Mise à jour',
        },
        appInvitation: {
            sureCancel: 'Êtes-vous sûr de vouloir annuler ?',
            once: 'Une fois que vous aurez quitté le site, vous devrez suivre le lien figurant dans votre courrier électronique pour réactiver votre compte.',
            leave: 'Laisser',
            stay: 'Séjour',
            welcome: 'Bienvenue à NEOYOT',
            sent: 'Envoyé',
            sentExplanation:
                "Un lien d'activation d'un nouveau compte a été envoyé à votre adresse électronique. Si le message n'est pas dans votre boîte de réception, veuillez vérifier votre dossier Spam.",
            sendNew: "Envoyer un nouveau lien d'invitation",

            enterEmail:
                "Veuillez saisir l'adresse électronique du compte que vous souhaitez activer.",
            notFound:
                "Nous sommes désolés, mais nous n'avons trouvé aucun compte enregistré pour cet e-mail.",
            sendNewLink: 'Envoyer un nouveau lien',
            cancel: 'Annuler',
            hi: 'Bonjour {name}, définissez un nouveau mot de passe pour activer votre compte',
            email: 'E-mail',
            newPassword: 'Nouveau mot de passe',
            eightChars:
                'Le nouveau mot de passe doit comporter au moins 8 caractères.',
            setNew: 'Définir un nouveau mot de passe et activer',
            expiredHeading: 'Invitation expirée',
            sevenDays:
                "Le lien d'invitation n'est valable que pendant 7 jours. Veuillez envoyer un nouveau lien d'invitation à votre adresse électronique.",
            sendToMyEmail: 'Envoyer un nouveau lien à mon e-mail',
            goBack: 'Retourner',
        },
        pages: {
            services: {
                heading: 'Services',
            },
            login: {
                heading: "Bienvenue à bord de l'abonnement à un yacht",
                login: 'Se connecter',
                email: 'E-mail',
                password: 'Mot de passe',
                forgotPassword: 'Mot de passe oublié ?',
            },
            resetPassword: {
                resetPassword: 'Réinitialiser le mot de passe',
                desc: "Saisissez l'adresse électronique associée à votre compte et nous vous enverrons des instructions pour réinitialiser votre mot de passe.",
                emailEmpty: "L'e-mail est vide",
                couldntFind:
                    "Nous n'avons pas trouvé de compte associé à cet e-mail. Veuillez vérifier à nouveau que vous l'avez saisi correctement. Si vous rencontrez toujours des difficultés, veuillez contacter NEOYOT.",
                resetLinkSent: 'Envoi du lien de réinitialisation',
                instructionsSent:
                    'Les instructions relatives à la réinitialisation de votre mot de passe ont été envoyées à votre adresse électronique. Si le message ne se trouve pas dans votre boîte de réception, veuillez vérifier votre dossier Spam.',
                newPassword: 'Nouveau mot de passe',
                eightChars:
                    'Votre mot de passe doit comporter au moins 8 caractères.',
                expiredHeading: 'Demande expirée',
                expiredExplanation:
                    'Nous sommes désolés, mais cette demande a expiré car elle a été faite il y a plus de 24 heures, ou vous avez fait une nouvelle demande. Veuillez formuler une nouvelle demande.',
                newRequest: 'Faire une nouvelle demande',
                cancel: 'Annuler',
                success: 'Votre mot de passe a été réinitialisé',
                loggedIn: 'Vous êtes maintenant connecté',
            },
            home: {
                yourNextBooking: 'Votre prochaine réservation',
                notSubscribedToAny: "Vous n'êtes abonné à aucun bateau",
                noYachtsInCollection: 'Aucun yacht dans la collection.',
                accountActivated: 'Compte activé',
                loggedIn: 'Vous êtes maintenant connecté',
                goodMorning: 'Bonjour, {first_name}',
                goodAfternoon: 'Bonjour, {first_name}',
                goodEvening: 'Bonjour, {first_name}',
                findAvailable: 'Trouver la disponibilité',
                today: "Aujourd'hui",
                tomorrow: 'Demain',
                other: 'Autres Services',
                search: 'Recherche',
                featured: 'Flotte NEOYOT',
                allTimes: 'Toutes les fois',
                subscriptionExpiredMessage: 'Un de vos abonnements a expiré',
                yourYachts: 'Vos yachts',
                news: 'Actualités',
                noYachts: 'Aucun yacht actif dans la collection',
            },
            browse: {},
            messages: {
                viewConversation: {
                    button: 'Envoyer',
                    typeSomething: 'Tapez quelque chose...',
                    startOfConvo: 'Début de la conversation',
                    startWith:
                        'Commencer une nouvelle conversation avec Captain',
                },
                explanation:
                    'Envoyer un message aux capitaines avec lesquels vous avez effectué des réservations.',
                captain: 'Capitaine',
                sendNew: 'Envoyer un nouveau message',
                you: 'Vous : ',
                new: 'Nouveau',
                empty: "Vous pouvez envoyer un message aux capitaines avec lesquels vous avez fait des réservations ici. Vous n'avez pas fait de réservations",
                notFound: 'Non trouvé',
                cantFindConversation: 'Impossible de trouver une conversation',
                notFoundDesc:
                    "Désolé, nous n'avons pas trouvé la conversation que vous recherchiez. Il se peut qu'elle ait été supprimée, déplacée ou modifiée. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter NEOYOT",
            },
            viewYacht: {
                chooseBooking: 'Choisir la réservation',
                specifications: 'Spécifications',
                documents: 'Documents',
                engines: 'Moteurs',
                flag: 'Drapeau',
                notSpecified: 'Non spécifié',
                readMore: 'En savoir plus',
                readLess: 'Lire moins',
                mapLocation: 'Localisation sur la carte',
                location: 'Localisation',
                length: 'Longueur',
                yearOfManufacture: 'Année de fabrication',
                beam: 'Beam',
                weight: 'Poids',
                hullLength: 'Longueur de la coque',
                hullMaterial: 'Matériau de la coque',
                width: 'Largeur',
                navigationCategory: 'Catégorie de navigation',
                fuelCapacity: 'Capacité en carburant (litres)',
                notSubscribed: "Vous n'êtes pas abonné à ce voilier",
                notFound: 'Non trouvé',
                yachtNotFound: 'Yacht non trouvé',
                notFoundDesc:
                    "Désolé, nous n'avons pas trouvé le yacht que vous recherchiez. Il se peut qu'il ait été supprimé, déplacé ou modifié. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter NEOYOT.",
            },
            bookings: {
                tapToViewMore: 'Pointez pour afficher',
                activeBookings: 'Actif',
                previousBookings: 'Précédent',
                noActive: 'Aucune réservation active trouvée',
                seePrevious: 'Voir les réservations expirées précédentes',
                noPrevious: "Aucune réservation expirée n'a été trouvée",
                seeActive: 'Voir les réservations actives',
                viewAllBookings: 'Voir toutes les réservations',
                cannotCancelTitle: "Impossible d'annuler la réservation",
                afterDeadline:
                    "Il a dépassé le délai d'annulation de {hours} heures",
                areYouSureCancel:
                    'Êtes-vous sûr de vouloir annuler cette réservation ?',
                willNotGetDaysBack:
                    "Vous ne récupérerez pas vos jours, sauf en cas d'annulation pour cause de mauvais temps.",
                iAmCancellingDueToBadWeather:
                    "J'annule pour cause de mauvais temps",
                cannotReverse: 'Cette action est irréversible.',
                singularToken: '{tokens} jour seront remboursés',
                multipleTokens: '{tokens} jours seront remboursés',
                reasonPlaceholder: "Motif de l'annulation (facultatif)...",
                yes: 'Oui',
                no: 'Non',
                booking: 'Réservation',
                bookingCreated: 'Réservation créée',
                bookingInOut: "Réservation de l'enregistrement et du départ",
                captain: 'Capitaine',
                tapToSendMessage: 'Appuyez sur pour envoyer un message',
                captainNotFound: 'Capitaine introuvable',
                status: 'Statut',
                tokensUsed: 'Jours utilisés',
                cancelDeadline: 'Annuler le délai',
                notesAndSpecialRequests: 'Notes et demandes spéciales',
                didNotProvide:
                    "Vous n'avez pas fourni de notes ou de demandes spéciales",
                cancelBooking: 'Annuler la réservation',
                cancellationInProgress: 'Annulation de votre réservation...',
                bookingType: 'Type de réservation',
                cancelError:
                    "Nous n'avons pas pu annuler votre réservation en raison d'une erreur inattendue. Veuillez contacter NEOYOT",
                ended: 'Terminé',
                active: 'Actif, fin',
                starts: 'Commence',
                cancelled: 'Annulé',
                bookingID: 'ID de réservation',
                cantFindBooking: 'Impossible de trouver cette réservation',
                cantFindBookingDesc:
                    "Nous ne parvenons pas à trouver cette réservation. Il se peut qu'elle ait été supprimée ou modifiée, veuillez retourner à la page précédente et essayer de retrouver votre réservation. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter NEOYOT",
                yourBookingHasBeenCancelled: 'Votre réservation a été annulée',
                daysNotRefundedOneHourAfterDeparture:
                    "Malheureusement, vos journées ne seront pas remboursées car vous annulez plus d'une heure après le départ.",
            },
            bookNow: {
                slotSelector: {
                    title: 'Réserver {name}',
                    whatBookingType:
                        'Quel type de réservation souhaitez-vous effectuer ?',
                    howLong: 'Pour quelle durée souhaitez-vous réserver ?',
                    when: 'Quand souhaitez-vous réserver ?',
                    slotsAvailableInTheNext:
                        'Jours disponibles dans la prochaine {daysOrHours}',
                    available: 'Créneaux disponibles en {date}',
                    explanation:
                        "Veuillez sélectionner vos dates d'arrivée et de départ dans le calendrier ci-dessous.",
                    availableFrom: 'Disponible à partir de',
                    button: "Sélectionner l'enregistrement et le départ",
                    between: 'Entre',
                    durationBetween: 'La durée doit être comprise entre',
                    oneHour: '1 heure à',
                    to: 'à',
                    moreThan: 'Plus de',
                    durationMoreThan:
                        'Duration must be more La durée doit être supérieure à',
                    month: 'Mois',
                    year: 'Année',
                    noneFound:
                        " Aucun créneau disponible n'a été trouvé en {month} avec la durée de réservation sélectionnée.",
                    noSlotsFound: "Aucun jour disponible n'a été trouvé",
                    try: 'Essayez de modifier la durée de la réservation ou le mois.',
                    day: 'jour',
                    hour: 'heure',
                    availableToday: 'Jours disponibles',
                    displayAvailableSlotsErrorTitle:
                        "Impossible d'afficher les jours disponibles",
                    displayAvailableSlotsError:
                        "Une erreur s'est produite. Veuillez nous informer de cette erreur.",
                    sorryForInconvenience:
                        'Nous sommes désolés pour les désagréments occasionnés',
                    expired: 'EXPIREE',
                    subscriptionExpired: 'Abonnement expiré',
                    subscriptionExpiredDesc:
                        'Vous ne pouvez pas faire de réservation pour ce type, car votre abonnement pour ce type de réservation a expiré. Veuillez contacter NEOYOT pour renouveler votre abonnement.',
                    contactSupport: 'Contacter le support',
                    earliestSubscriptionHasntStarted:
                        "Votre abonnement anticipé à ce yacht n'a pas encore commencé",
                    itIsScheduledToStartedOn:
                        "Il est prévu qu'il commence le {date}. Veuillez vérifier ultérieurement.",
                    ifYouHaveAnyQuestions:
                        "Si vous avez des questions, veuillez contacter le service d'assistance.",
                    yourBooking: 'Votre réservation',
                    bookingCantBeMade:
                        "Nous sommes désolés, mais cette réservation ne peut être effectuée. Veuillez contacter le service d'assistance si le problème persiste.",
                    book: 'Réservez',
                    availableOnly: 'Disponible',
                    booked: 'Réservé',
                    accreditationHasntStartedButCanBook:
                        "Votre abonnement le plus ancien n'a pas encore commencé, mais vous pouvez effectuer une réservation dès le début de votre abonnement.",
                    yourSubscriptionFrom:
                        'Votre abonnement actuel est valable du {start} au {end}',
                },
                bookingDetails: {
                    title: 'Détails de la réservation',
                    conditionsFor: 'Conditions',
                    maxBooking:
                        'Durée maximale de la réservation {hours} heures',
                    cancelDeadline: "Délai d'annulation {hours}",
                    checkIn: 'Check-in',
                    checkOut: 'Check-out',
                    date: 'Date',
                    hour: 'Heure',
                    duration: 'Durée',
                    tokensRequired: 'Jours requis',
                    yourTokens: 'Vos jours',
                    next: 'Suivant',
                    noTokensRequired: "Aucun jour n'est requis",
                    noConditions: 'Pas de conditions',
                    moreThan: 'Plus de',
                    invalid: 'Invalide',
                    notEnoughForMoreThan:
                        "Vous n'avez pas assez de jours disponibles pour une réservation de plus de",
                    errors: {
                        checkOutAfterMaxEnd:
                            'Le départ ne peut avoir lieu après la fin de la période maximale {time}',
                        durationMoreThanMaximum:
                            'La durée de la réservation est supérieure au maximum autorisé de {time}. Veuillez réduire la durée de la réservation',
                        durationMoreThan:
                            'La durée de la réservation doit être supérieure à {time}. Veuillez augmenter la durée de la réservation',
                        durationLessThan:
                            'La durée de la réservation est inférieure au minimum autorisé de {time}. Veuillez augmenter la durée de la réservation',
                        inOutSame:
                            "L'enregistrement et le départ ne peuvent pas être identiques",
                        inAfterOut:
                            "L'enregistrement ne peut avoir lieu après le départ.",
                        inBeforeStart:
                            "L'enregistrement ne peut avoir lieu avant le début minimum de {time}.",
                        atLeastOneHour: "La durée doit être d'au moins 1 heure",
                        notEnoughTokens: "Vous n'avez pas assez de jours disponibles pour effectuer cette réservation",
                    },
                    daysAvailableAccordingToYourSubscription:
                        'Jours disponibles chaque mois en fonction de votre abonnement et jours requis pour effectuer cette réservation',
                    month: 'Mois',
                    available: 'Disponible',
                    required: 'Exigée',
                },
                bookingConfirmation: {
                    success: 'Succès',
                    bookingMade: 'Votre réservation a été effectuée',
                    confirmBooking: 'Confirmer la réservation',
                    duration: 'Durée',
                    tokensRequired: 'Jours requis',
                    notes: 'Notes et demandes spéciales',
                    error: 'Erreur',
                    processing: 'Traitement...',
                    anotherBookingMade:
                        "Nous sommes désolés, mais une autre réservation a été faite dans ce créneau. Nous n'avons pas pu terminer votre réservation. Veuillez essayer de réserver un autre créneau<br>Nous vous prions de nous excuser pour la gêne occasionnée.",
                    notEnoughTokens:
                        "Nous sommes désolés, mais vous n'avez pas assez de jours disponibles pour effectuer cette réservation.",
                    bookingError:
                        "Nous sommes désolés, mais cette réservation n'a pas pu être effectuée. Il s'agit d'une erreur inattendue et nous vous prions de nous en excuser. Veuillez informer NEOYOT de ce problème. <Dans l'intervalle, essayez de réserver une autre date.",
                },
                tokens: 'jours',
            },
            pageNotFound: {
                heading: 'Non trouvé',
                explanation:
                    "La page que vous recherchez n'a pas pu être trouvée",
                location:
                    "Son emplacement a pu être modifié ou il n'existe pas. Nous vous prions de nous excuser pour ce désagrément.",
                back: 'Retour à la maison',
            },
            more: {
                logout: 'Déconnexion',
                contactSupport: 'Contacter le support',
                subscriptionEnds: "Fin de l'abonnement",
                tapToEdit: 'Pointez pour modifier',
                noSubscriptions: "Vous n'avez pas d'abonnement",
                active: 'Actif',
                notStarted: 'Pas commencé',
                expired: 'Expiré',
                startsIn: 'Commence en',
                myAccount: {
                    myAccount: 'Mon compte',
                    firstName: 'Prénom',
                    lastName: 'Nom de famille',
                    email: 'E-mail',
                    phone: 'Téléphone',
                    secondaryPhone: 'Téléphone secondaire',
                    address: 'Adresse',
                    additionalAddress: 'Adresse supplémentaire',
                    postcode: 'Code postal',
                    city: 'Ville',
                    country: 'Pays',
                    tapToChangePhoto: 'Pointez pour changer de photo',
                    resetPasswordModal: {
                        currentPassword: 'Mot de passe actuel',
                        newPassword: 'Nouveau mot de passe',
                        passwordLengthError:
                            'Le nouveau mot de passe doit comporter au moins 8 caractères.',
                    },
                },
                contactSupportPage: {
                    phone: 'Téléphone',
                    website: 'Site web',
                },
                mySubscription: {
                    mySubscription: 'Mes abonnements',
                    moreDetails: 'Plus de détails',
                    startDate: 'Date de début',
                    expiryDate: "Date d'expiration",
                    expires: 'Expire en',
                    expired: 'Expiré',
                    currentSubscriptionLength: "Durée actuelle de l'abonnement",
                    subscriptionAmount: "Montant de l'abonnement",
                    day: '{day} jour',
                    days: '{days} jours',
                    hours: '{hours} heures',
                    hour: '{hour} heure',
                    minutes: 'minutes',
                    seconds: 'secondes',
                    length: 'Durée',
                    amount: 'Montant',
                    yachtAccess: 'Accès aux yachts',
                    tokensAvailable: 'Jours disponibles',
                    noSubscriptions: "Vous n'avez pas d'abonnement",
                    spansMultipleMonths:
                        "Cet abonnement s'étend sur plusieurs mois. Les jours disponibles pour la réservation seront réinitialisés au début de chaque mois.",
                    month: 'Mois',
                    available: 'Disponible',
                },
                other: 'Autres',
                invoices: {
                    invoices: 'Factures',
                    invoice: 'Facture',
                    explanation:
                        "Une fois que vous avez effectué un paiement, les factures correspondant à vos paiements s'affichent ici. Il peut s'écouler un certain temps avant que la facture ne s'affiche.",
                    questions:
                        "Si vous avez des questions, n'hésitez pas à contacter  ",
                    contactUs: 'nous contacter',
                    noneFound: "Aucune facture n'a été trouvée.",
                },
                subscriptionContract: {
                    heading: "Contrat d'abonnement",
                },
                feedback: {
                    feedback: 'Envoyer un retour commentaire',
                    feedbackSent: 'Feedback envoyé',
                    thanks: 'Merci pour votre suggestion.',
                    explanation:
                        "Si vous avez des suggestions d'amélioration ou si vous avez rencontré des problèmes, vous pouvez nous en faire part ici. Toutefois, si vous avez besoin de nous contacter immédiatement, veuillez",
                    contactUs: 'nous contacter',
                    instead: 'au lieu de',
                    appreciate:
                        'Nous apprécions grandement vos commentaires, car nous nous efforçons de créer la meilleure expérience possible.',
                    message: 'Message',
                    invalid: 'Le message ne peut pas être vide',
                    rate: 'Notez votre expérience',
                    anonymous:
                        "Envoyez ce retour d'information de manière anonyme",
                    send: 'Envoyer',
                    type: 'Tapez votre message ici...',
                },
                aboutApp: {
                    aboutApp: "À propos de l'app",
                    tos: "Conditions d'utilisation de NEOYOT",
                    tosDescription:
                        "Lire les conditions d'utilisation de NEOYOT",
                    osl: 'Licences de logiciels libres',
                    oslDescription:
                        'Détails de la licence pour les logiciels libres',
                    version: 'Version',
                },
            },
            food: {
                errors: {
                    failedToGetItemsInThisCategory:
                        "ERREUR : Échec de l'obtention des éléments de cette catégorie",
                    selectedDeliverytimeBeforeNow:
                        "Le délai de livraison choisi doit être antérieur à l'heure actuelle.",
                    someItemsBecameUnavailable:
                        'Some items in your cart has become unavailable',
                    unavailableItemsRemoved:
                        'Les articles ci-dessus ont été retirés de votre panier car ils sont indisponibles ou en rupture de stock. Veuillez réessayer de passer votre commande.',
                    orderCouldntBeCompleted:
                        "Une erreur inconnue s'est produite. Votre commande n'a pas pu être finalisée",
                    notSubscribedToAnyYachts:
                        "Une livraison ne peut pas être effectuée parce que vous n'êtes pas abonné à un yacht.",
                    failedToGetItemsInThisCuisine:
                        "ERREUR : Échec de l'obtention d'éléments dans cette cuisine",
                    orderNotFound: 'Ordre non trouvé',
                    cantFindOrder:
                        "Désolé, nous n'avons pas trouvé la commande que vous recherchiez. Veuillez réessayer",
                },
                nav: {
                    featured: 'En vedette',
                    cuisines: 'Cuisines',
                    categories: 'Catégories',
                    orders: 'Commandes',
                },
                itemNameOrDescription: "Nom ou description de l'article",
                boat: 'Bateau',
                deliveryInstructionsPlaceholder:
                    'Mettre des articles dans le réfrigérateur du pont arrière',
                addYourRequest:
                    'Ajouter votre demande (à la discrétion du restaurant)',
                basePrice: 'Prix de base',
                optionalMax: 'Facultatif, max {max}',
                optional: 'En option',
                pickAtLeast: 'Choisissez au moins {min}',
                pickMax: 'Choisir {min}, max {max}',
                pickOne: 'Choisir 1',
                pick: 'Choisir',
                noteToRestaurant: 'Note au restaurant',
                itemAlreadyInBasket: 'Cet article est déjà dans votre panier',
                removeFromBasket: 'Retirer du panier',
                updateItem: 'Mise à jour de larticle',
                addToBasket: 'Ajouter au panier',
                selectAtLeast: 'Sélectionnez au moins {items}',
                dontSelectMoreThan: 'Ne pas sélectionner plus de {items}',
                cancellationReasonOptional:
                    "Motif de l'annulation (facultatif)",
                theReasonForYourCancellation:
                    "La raison de l'annulation (facultatif)",
                cancelOrder: 'Annuler la commande',
                dontCancel: 'Ne pas annuler',
                categories: 'Catégories',
                mostPopular: 'Les plus populaires',
                leastPopular: 'Le moins populaire',
                highestPrice: 'Prix le plus élevé',
                lowestPrice: 'Prix le plus bas',
                aToZ: 'De A à Z',
                zToA: 'De Z à A',
                noItemsWereFound: "Aucun élément n'a été trouvé",
                deliveryDetails: 'Détails de la livraison',
                whenToDeliver: 'Quand livrer',
                selectDeliveryTime: 'Sélectionner le délai de livraison',
                deliverTo: 'Livrer à',
                deliveryInstructions: 'Instructions de livraison',
                subtotal: 'Sous-total',
                sendingYourOrder: 'Envoi de votre commande...',
                confirm: 'Confirmer',
                now: 'Maintenant',
                specific: 'À un moment précis',
                popular: 'Populaire',
                viewAll: 'Voir tous',
                recentlyAdded: 'Récemment ajouté',
                foodAndBeverages: 'Alimentation et boissons',
                search: 'Recherche',
                basket: 'Panier',
                item: 'article',
                items: 'articles',
                myBasket: 'Mon panier',
                edit: 'Editer',
                yourBasketIsEmpty: 'Votre panier est vide',
                checkout: 'Sortie de caisse',
                done: 'Terminé',
                pleaseTryAgain: 'Veuillez réessayer',
                yourOrderHasBeenPlaced: 'Votre commande a été passée',
                youWillReceiveAnEmail:
                    'Vous recevrez un courriel vous informant de la livraison.',
                myOrders: 'Mes commandes',
                order: 'Commande',
                weveReceivedYourOrder: 'Nous avons reçu votre commande',
                preparingYourOrder: 'Préparation de la commande',
                delivering: 'Délivrer',
                delivered: 'Livré',
                cancelledBySubscriber: "Annulé par l'abonné",
                weCouldntFulfillYourOrder:
                    "Nous n'avons pas pu honorer votre commande",
                unknownItem: 'Objet inconnu',
                moreItems: '+ {number} autres éléments',
                noResultsFound: 'Aucun résultat trouvé',
                notSpecified: 'Non spécifié',
                cuisine: 'Cuisine',
                other: 'Autres',
                updateItemInBasket: "Mise à jour de l'article dans le panier",
                unavailable: 'Indisponible',
                youHaveRequestedThisOrderToBeDeliveredAt:
                    'Vous avez demandé que cette commande soit livrée à',
                youHaveRequestedThisOrderToBeDeliveredAsap:
                    'Vous avez demandé que cette commande soit livrée dans les plus brefs délais.',
                reason: 'Raison',
                noReasonGiven: "Aucune raison n'est donnée",
                createdOn: 'Créé le',
                orderDetails: 'Détails de la commande',
                unknownPreference: 'Préférence inconnue',
                contactNEOYOT: 'Contacter NEOYOT',
                inBasket: 'Dans le panier',
            },
        },
        misc: {
            later: 'Plus tard',
            engine: 'Moteur',
            horsepower: 'puissance',
            transmission: 'transmission',
            propellers: 'hélices',
            uploading: 'Téléchargement',
            none: 'Aucun',
            tokens: 'JOURS',
            refresh: 'Rafraîchir',
            cannotBookNoCaptain: 'Impossible de réserver - pas de capitaine',
            back: 'Retour',
            goBack: 'Retourner',
            modal: {
                done: 'Terminé',
                cancel: 'Annuler',
                save: 'Enregistrer',
                confirmLogout: 'Êtes-vous sûr de vouloir vous déconnecter ?',
                logout: 'Déconnexion',
            },
            day: 'jour',
            days: 'jours',
            hours: 'heures',
            minutes: 'minutes',
        },
    },
};
